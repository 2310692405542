import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Image } from 'react-datocms'
import { useIntl } from 'react-intl'

import { today } from '../utils'
import { NextConcertList } from '../components/ConcertList'
import AnimatedLetters from '../components/AnimatedLetters'
import media from '../styles/mq'
import { ButtonLink } from '../components/Buttons'

const Page = styled.div`
  height: 100%;
`

const Flex = styled.div`
  ${media.greaterThan('medium')} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Hero = styled.div`
  position: relative;
  flex: 1;
  ${media.lessThan('medium')} {
    width: 100%;
    margin: 0;
  }
`

const Title = styled.div`
  position: relative;
  z-index: 1;
  --top-margin: -16vw;
  --left-margin: 14vw;
  padding: 0 var(--space-small) var(--space-small);
  margin-top: calc(var(--top-margin) / 2);
  ${media.greaterThan('medium')} {
    top: 0;
    margin-top: 0;
    padding-bottom: var(--space-largest);
    color: var(--color-white);
    --top-margin: -5vw;
    --left-margin: 6vw;
  }
  > .name {
    font-family: var(--font-family-serif);
    margin: 0 auto;
    font-size: 20vw;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    ${media.greaterThan('medium')} {
      font-size: 6vw;
    }
    > * {
      position: relative;
      &:last-child {
        top: var(--top-margin);
        left: var(--left-margin);
      }
    }
  }
  > .baseline {
    position: relative;
    margin-top: calc(var(--top-margin) - 1rem);
    margin-left: var(--left-margin);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-uppercase);
    font-size: var(--font-size-small);
  }
`

const Concerts = styled.div`
  position: relative;
  background-color: var(--color-lighter);
  :before {
    content: '';
    z-index: -1;
    background-color: var(--color-lighter);
    position: absolute;
    height: 80px;
    width: 100%;
    top: -80px;
    left: 0;
  }
  > div {
    position: relative;
    padding: var(--space-medium) 0;
  }
`

const linkCss = css`
  position: absolute;
  top: var(--space-medium);
  right: 0;
  text-transform: uppercase;
  font-size: var(--font-size-smaller);
  &:hover {
    opacity: 0.7;
  }
`

const fixedImgOnDesktopCss = css`
  ${media.greaterThan('medium')} {
    position: absolute !important;
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    height: 100%;
    max-width: none !important;
    > div {
      padding-top: 0 !important;
      height: 100% !important;
      max-width: none !important;
    }
    > picture {
      height: 100%;
    }
  }
`

const IndexPage = ({
  data: {
    dato: { page, pageConcert, allConcerts }
  }
}) => {
  const { formatMessage: t } = useIntl()

  const { name, baseline, cover } = page
  const [firstName, lastName] = name.split(' ')

  /* transforms "violoniste, altiste, muscien de jazz" to
   * violoniste,
   * altiste,
   * musicien de jazz
   */
  const baselines = useMemo(
    () =>
      baseline
        .split(', ')
        .map((b, i, arr) => (i < arr.length - 1 ? b + ',' : b)),
    [baseline]
  )

  const newConcerts = useMemo(() => {
    const d = today()
    const formattedConcertDates = allConcerts
      .reverse()
      .map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date)
      }))

    const pastIndex = formattedConcertDates.findIndex((c) => c.date >= d)
    return formattedConcertDates.slice(pastIndex, pastIndex + 2)
  }, [allConcerts])

  return (
    <Page>
      <Flex>
        <Hero className="c-medium">
          <Title>
            <div className="name">
              <AnimatedLetters text={firstName} delay={1000} trail={100} />
              <AnimatedLetters text={lastName} delay={1500} trail={100} />
            </div>
            <div className="baseline">
              {baselines.map((b, i) => (
                <AnimatedLetters key={i} delay={4000}>
                  {b}
                </AnimatedLetters>
              ))}
            </div>
          </Title>
          <Image data={cover.fluidFullWidth} css={fixedImgOnDesktopCss}  />
        </Hero>
        <Concerts>
          <div className="c-medium">
            <Link to={pageConcert.slug} className="m-hide" css={linkCss}>
              {t({ id: 'all concert dates' })}
            </Link>
            <NextConcertList concerts={newConcerts} />
            <ButtonLink className="m-only" to={pageConcert.slug}>
              {t({ id: 'all concert dates' })}
            </ButtonLink>
          </div>
        </Concerts>
      </Flex>
    </Page>
  )
}

export default IndexPage

export const query = graphql`
  query($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      pageConcert(locale: $locale) {
        id
        slug: localeSlug
      }
      page: pageHome(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
        name
        baseline
        cover {
          ...ImageFragment
        }
      }
      allConcerts(locale: $locale, orderBy: date_DESC) {
        ...ConcertRecordFragment
      }
    }
  }
`
